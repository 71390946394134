<template>
  <div class="searchbox">
    <div class="title"><b>证书查询</b></div>
    <div class="flex">
      <div class="flex_item">
        <div class="flex">
          <span>证书号：</span>
          <div class="flex_item"><input type="text" placeholder="" v-model="query.certNo"></div>
        </div>
      </div>
      <div class="flex_item">
        <div class="flex">
          <span>企业名称：</span>
          <div class="flex_item"><input type="text" placeholder="" v-model="query.name"></div>
        </div>
      </div>
      <div class="flex_item">
        <div class="flex">
          <span>证书类别：</span>
          <!--                <div class="flex_item"><input type="text" placeholder="必填"></div>-->
          <div class="flex_item">
            <div class="option">
              <select v-model="query.selectType" >
                <option :value="item.id" :label="item.name" v-for="item in types" :key="item.id">请选择证书类别</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="flex_item" v-if="$store.state.stations.id != 1004">
        <div class="flex">
          <span>验证码：</span>
          <div class="flex_item"><input type="text" placeholder="必填" v-model="query.code"></div>
        </div>
      </div>
      <div v-if="$store.state.stations.id != 1004">
        <img style="margin-left: 0" :src="codeUrl" class="code"  @click="getCode" />
      </div>
    </div>
    <button type="button" class="btn" @click="submitQuery">查询</button>
  </div>
</template>

<script>

export default {
  name: "certification",
  data(){
    return {
      codeUrl:'',
      uuid:'',
      query:{
        certNo:"",
        name:"",
        selectType:0,
        code:'',
        uuid:''
      },
      types:[],
    }
  },
  created() {
    //获取中联分类数据
    this.getTypes();
  },
  mounted() {
    this.getCode()
  },
  methods:{
    async getTypes(){
      let res = await this.$api.getCertTypes({});
      console.log(res);
      this.types = res.data.data
      this.types.unshift({
        id:0,
        name:""
      })
    },
    submitQuery(){
      if(!this.query.certNo.trim()){
        return this.$msgbox('请输入证书号','提示')
      }
      if(!this.query.name.trim()){
        return this.$msgbox('请输入企业名称','提示')
      }
      if(!this.query.selectType){
        return this.$msgbox('请选择证书类别','提示')
      }
      if(!this.query.code){
        return this.$msgbox('请先输入验证码','提示')
      }
      this.$emit("zlQueryClick",this.query)
    },
    async getCode(){
      let res = await this.$api.getCaptcha({});
      console.log(res)
      this.codeUrl = "data:image/gif;base64," + res.data.data.img;
      this.query.uuid = res.data.data.uuid
    }
  }
}
</script>

<style scoped>

</style>
