<template>
  <div class="searchbox">
    <div class="title"><b>证书查询</b></div>
    <div class="flex">
      <div class="flex_item">
        <div class="flex">
          <span>证书编号：</span>
          <div class="flex_item"><input type="text" placeholder="" v-model="query.certNo"></div>
        </div>
      </div>
      <div class="flex_item">
        <div class="flex">
          <span>企业名称：</span>
          <div class="flex_item"><input type="text" placeholder="" v-model="query.name"></div>
        </div>
      </div>
      <div class="flex_item">
        <div class="flex">
          <span>产品型号：</span>
          <div class="flex_item"><input type="text" placeholder="" v-model="query.productModel"></div>
        </div>
      </div>
      <div class="flex_item">
        <div class="flex">
          <span>产品名称：</span>
          <div class="flex_item"><input type="text" placeholder="" v-model="query.productName"></div>
<!--          <img src="/images/1_05.jpg" class="code" />-->
        </div>
      </div>
      <div class="flex_item" v-if="$store.state.stations.id != 1004">
        <div class="flex">
          <span>验证码：</span>
          <div class="flex_item"><input type="text" placeholder="必填" v-model="query.code"></div>
        </div>
      </div>
      <div v-if="$store.state.stations.id != 1004">
        <img style="margin-left: 0" :src="codeUrl" class="code"  @click="getCode" />
      </div>
    </div>
    <button type="button" class="btn" @click="sumitQuery()">查询</button>
  </div>
</template>

<script>
export default {
  name: "certification",
  data(){
    return {
      years:[],
      query:{
        certNo:"",
        name:"",
        issueDate:"",
        parameter:"",
        productModel:"",
        productName:"",
        code:'',
        uuid:''
      },
      codeUrl:'',
      uuid:''
    }
  },
  mounted() {
    if(this.$store.state.stations.id != 1004){
      this.getCode()
    }

  },
  methods:{
    sumitQuery(){
      if(!this.query.code && this.$store.state.stations.id != 1004){
        return this.$msgbox('请先输入验证码','提示')
      }
      this.$emit("queryClick",this.query)
    },
    async getCode(){
      let res = await this.$api.getCaptcha({});
      console.log(res)
      this.codeUrl = "data:image/gif;base64," + res.data.data.img;
      this.query.uuid = res.data.data.uuid
    }
  }
}
</script>

<style scoped>

</style>
